"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFullCalendarStore = void 0;
const pinia_1 = require("pinia");
exports.useFullCalendarStore = (0, pinia_1.defineStore)("fullCalendarStore", {
    state: () => ({
        clipboard: null,
        draggedFrom: null,
    }),
    getters: {},
    actions: {},
});
