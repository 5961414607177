"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "reservation-model-col" };
const _hoisted_2 = {
    key: 0,
    class: "title"
};
exports.default = (0, vue_1.defineComponent)({
    __name: 'ReservationModalColumn',
    props: {
        title: {
            type: String,
            default: "",
        },
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (__props.title)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_2, (0, vue_2.toDisplayString)(__props.title), 1 /* TEXT */))
                    : (0, vue_2.createCommentVNode)("v-if", true),
                (0, vue_2.renderSlot)(_ctx.$slots, "default")
            ]));
        };
    }
});
