"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "flex flex-col w-full justify-center" };
const _hoisted_2 = {
    key: 0,
    class: "block pb-2 font-bold uppercase tracking-wide text-sm"
};
const vue_quill_1 = require("@vueup/vue-quill");
exports.default = (0, vue_1.defineComponent)({
    __name: 'TextEditor',
    props: {
        label: {
            type: String,
            required: false,
            default: "",
        },
        modelValue: String,
    },
    emits: ["update:modelValue"],
    setup(__props, { emit }) {
        const props = __props;
        const updateModel = (content) => {
            emit("update:modelValue", content);
        };
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("label", _hoisted_1, [
                (__props.label)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_2, (0, vue_2.toDisplayString)(__props.label), 1 /* TEXT */))
                    : (0, vue_2.createCommentVNode)("v-if", true),
                (0, vue_2.createVNode)((0, vue_2.unref)(vue_quill_1.QuillEditor), {
                    theme: "snow",
                    toolbar: "full",
                    "content-type": 'html',
                    content: __props.modelValue,
                    "onUpdate:content": updateModel
                }, null, 8 /* PROPS */, ["content"])
            ]));
        };
    }
});
