<template>
  <thead>
    <tr class="table-head table-row">
      <th>Státusz</th>
      <th>Foglalt Időpont</th>
      <th>Rendszám</th>
      <th>Jármű</th>
      <th>Állás</th>
      <th class="long">Foglalt szolgáltatás</th>
      <th>Összeg</th>
      <th>Számla</th>
      <th></th>
      <th></th>
    </tr>
  </thead>
</template>
