"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "form-input button" };
exports.default = (0, vue_1.defineComponent)({
    __name: 'CustomButton',
    props: {
        btnType: {
            type: String,
            default: "",
        },
        defaultIcon: {
            type: Boolean,
            default: true,
        },
        to: {
            type: [String, Array],
            default: "",
        },
    },
    emits: ["click"],
    setup(__props, { emit: onClick }) {
        function emitClick(event) {
            onClick("click", event);
        }
        return (_ctx, _cache) => {
            const _component_font_awesome_icon = (0, vue_2.resolveComponent)("font-awesome-icon");
            const _component_router_link = (0, vue_2.resolveComponent)("router-link");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (__props.to)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_router_link, {
                        key: 0,
                        to: `${__props.to}`,
                        class: (0, vue_2.normalizeClass)(["custom-btn", {
                                'button-success': __props.btnType === 'success',
                                'button-danger': __props.btnType === 'danger',
                                'button-default': __props.btnType === 'default' || !__props.btnType,
                                'button-primary': __props.btnType === 'primary',
                                'button-transparent': __props.btnType === 'transparent',
                            }])
                    }, {
                        default: (0, vue_2.withCtx)(() => [
                            (0, vue_2.renderSlot)(_ctx.$slots, "default"),
                            (__props.btnType === 'success' && __props.defaultIcon)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_font_awesome_icon, {
                                    key: 0,
                                    class: "icon",
                                    icon: ['fas', 'angle-right']
                                }))
                                : (0, vue_2.createCommentVNode)("v-if", true),
                            (__props.btnType === 'danger' && __props.defaultIcon)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_font_awesome_icon, {
                                    key: 1,
                                    class: "icon",
                                    icon: ['far', 'trash-can']
                                }))
                                : (0, vue_2.createCommentVNode)("v-if", true),
                            ((__props.btnType === 'default' || __props.btnType === '') && __props.defaultIcon)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_font_awesome_icon, {
                                    key: 2,
                                    class: "icon",
                                    icon: ['fas', 'xmark']
                                }))
                                : (0, vue_2.createCommentVNode)("v-if", true),
                            (__props.btnType === 'primary' && __props.defaultIcon)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_font_awesome_icon, {
                                    key: 3,
                                    class: "icon",
                                    icon: ['fas', 'circle-plus'],
                                    size: "lg"
                                }))
                                : (0, vue_2.createCommentVNode)("v-if", true)
                        ]),
                        _: 3 /* FORWARDED */
                    }, 8 /* PROPS */, ["to", "class"]))
                    : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("button", {
                        key: 1,
                        class: (0, vue_2.normalizeClass)(["custom-btn", {
                                'button-success': __props.btnType === 'success',
                                'button-danger': __props.btnType === 'danger',
                                'button-default': __props.btnType === 'default' || !__props.btnType,
                                'button-primary': __props.btnType === 'primary',
                                'button-transparent': __props.btnType === 'transparent',
                            }]),
                        onClick: emitClick
                    }, [
                        (0, vue_2.renderSlot)(_ctx.$slots, "default"),
                        (__props.btnType === 'success' && __props.defaultIcon)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_font_awesome_icon, {
                                key: 0,
                                class: "icon",
                                icon: ['fas', 'angle-right']
                            }))
                            : (0, vue_2.createCommentVNode)("v-if", true),
                        (__props.btnType === 'danger' && __props.defaultIcon)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_font_awesome_icon, {
                                key: 1,
                                class: "icon",
                                icon: ['far', 'trash-can']
                            }))
                            : (0, vue_2.createCommentVNode)("v-if", true),
                        ((__props.btnType === 'default' || __props.btnType === '') && __props.defaultIcon)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_font_awesome_icon, {
                                key: 2,
                                class: "icon",
                                icon: ['fas', 'xmark']
                            }))
                            : (0, vue_2.createCommentVNode)("v-if", true),
                        (__props.btnType === 'primary' && __props.defaultIcon)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_font_awesome_icon, {
                                key: 3,
                                class: "icon",
                                icon: ['fas', 'circle-plus'],
                                size: "lg"
                            }))
                            : (0, vue_2.createCommentVNode)("v-if", true)
                    ], 2 /* CLASS */))
            ]));
        };
    }
});
