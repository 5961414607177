"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "calendar-block" };
const _hoisted_2 = {
    key: 0,
    class: "pointer-events-none"
};
const _hoisted_3 = { class: "hidden sm:block" };
const _hoisted_4 = { class: "sm:hidden" };
const _hoisted_5 = {
    key: 1,
    class: "pointer-events-none"
};
exports.default = (0, vue_1.defineComponent)({
    __name: 'CalendarBlock',
    props: {
        label: {
            type: String,
            default: "",
        },
        date: {
            type: String,
            default: "",
        },
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("button", _hoisted_1, [
                (__props.label !== '')
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_2, [
                        (0, vue_2.createElementVNode)("div", _hoisted_3, (0, vue_2.toDisplayString)(__props.label), 1 /* TEXT */),
                        (0, vue_2.createElementVNode)("div", _hoisted_4, (0, vue_2.toDisplayString)(__props.label.substring(0, 1)), 1 /* TEXT */)
                    ]))
                    : (0, vue_2.createCommentVNode)("v-if", true),
                (__props.date !== '')
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_5, (0, vue_2.toDisplayString)(__props.date), 1 /* TEXT */))
                    : (0, vue_2.createCommentVNode)("v-if", true)
            ]));
        };
    }
});
