"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = {
    key: 0,
    class: "time-block"
};
exports.default = (0, vue_1.defineComponent)({
    __name: 'TimeBlock',
    props: {
        time: {
            type: String,
            default: "",
        },
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return (__props.time)
                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("button", _hoisted_1, (0, vue_2.toDisplayString)(__props.time), 1 /* TEXT */))
                : (0, vue_2.createCommentVNode)("v-if", true);
        };
    }
});
