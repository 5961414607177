"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "form-input file" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { class: "file-input" };
const _hoisted_4 = { class: "content" };
const _hoisted_5 = { class: "file-input-text" };
const _hoisted_6 = ["name"];
exports.default = (0, vue_1.defineComponent)({
    __name: 'CustomFileInput',
    props: {
        name: {
            type: String,
            default: "",
        },
        text: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "file",
        },
        label: {
            type: String,
            default: "",
        },
    },
    setup(__props) {
        /*
        const emit = defineEmits(["update:modelValue"]);
        
        const updateModel = (event: any) => {
          emit("update:modelValue", event.target.files[0]);
        };*/
        return (_ctx, _cache) => {
            const _component_font_awesome_icon = (0, vue_2.resolveComponent)("font-awesome-icon");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (__props.label)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("label", _hoisted_2, (0, vue_2.toDisplayString)(__props.label), 1 /* TEXT */))
                    : (0, vue_2.createCommentVNode)("v-if", true),
                (0, vue_2.createElementVNode)("label", _hoisted_3, [
                    (0, vue_2.createElementVNode)("div", _hoisted_4, [
                        (__props.type === 'file')
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_font_awesome_icon, {
                                key: 0,
                                class: "icon",
                                icon: ['fas', 'file-arrow-up']
                            }))
                            : (0, vue_2.createCommentVNode)("v-if", true),
                        (__props.type === 'image')
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_font_awesome_icon, {
                                key: 1,
                                class: "icon",
                                icon: ['far', 'image']
                            }))
                            : (0, vue_2.createCommentVNode)("v-if", true),
                        (0, vue_2.createElementVNode)("div", _hoisted_5, (0, vue_2.toDisplayString)(__props.text), 1 /* TEXT */),
                        (0, vue_2.createElementVNode)("input", {
                            name: `${__props.name}`,
                            type: "file"
                        }, null, 8 /* PROPS */, _hoisted_6)
                    ])
                ])
            ]));
        };
    }
});
