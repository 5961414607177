"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = {
    key: 0,
    class: "label-mb-bottom"
};
const _hoisted_2 = ["type", "name", "placeholder", "value"];
const _hoisted_3 = ["innerHTML"];
exports.default = (0, vue_1.defineComponent)({
    __name: 'CustomInput',
    props: {
        label: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "text",
        },
        name: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        modelValue: {
            type: String,
            default: "",
        },
        errorMessage: {
            type: String,
            default: "",
        },
    },
    emits: ["update:modelValue"],
    setup(__props) {
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                class: (0, vue_2.normalizeClass)(["flex flex-col", { 'max-w-full w-full': __props.name === 'search' }])
            }, [
                (__props.label !== '')
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("label", _hoisted_1, (0, vue_2.toDisplayString)(__props.label), 1 /* TEXT */))
                    : (0, vue_2.createCommentVNode)("v-if", true),
                (0, vue_2.createElementVNode)("input", {
                    type: __props.type,
                    name: __props.name,
                    placeholder: __props.placeholder,
                    value: __props.modelValue,
                    class: (0, vue_2.normalizeClass)(["text-input", {
                            'max-w-full !w-full': __props.name === 'search',
                            'radio-input': __props.type === 'radio',
                            'checkbox-input': __props.type === 'checkbox',
                            '!bg-white scale-[1.154]': __props.name === 'gdpr',
                        }]),
                    onInput: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('update:modelValue', $event.target.value)))
                }, null, 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_2),
                (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("div", {
                    class: "error-message",
                    innerHTML: __props.errorMessage
                }, null, 8 /* PROPS */, _hoisted_3), [
                    [vue_2.vShow, __props.errorMessage !== '']
                ])
            ], 2 /* CLASS */));
        };
    }
});
