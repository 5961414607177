"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class UserRequest {
    constructor(plainPassword = null, email = "", groups = [], extraEmails = []) {
        this.plainPassword = plainPassword;
        this.email = email;
        this.groups = groups;
        this.extraEmails = extraEmails;
    }
}
exports.default = UserRequest;
