"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "form-input" };
const _hoisted_2 = { class: "checkbox-label" };
const _hoisted_3 = ["name", "checked"];
const _hoisted_4 = { class: "checkbox-div" };
const _hoisted_5 = { class: "checkbox-text" };
exports.default = (0, vue_1.defineComponent)({
    __name: 'CustomCheckbox',
    props: {
        name: {
            type: String,
            default: "",
        },
        text: {
            type: String,
            default: "",
        },
        modelValue: {
            type: Boolean,
            default: false,
        },
        checked: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["changeValue"],
    setup(__props, { emit }) {
        const props = __props;
        function updateValue() {
            emit("changeValue", props.name, !props.checked);
        }
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("label", _hoisted_2, [
                    (0, vue_2.createElementVNode)("input", {
                        type: "checkbox",
                        name: __props.name,
                        checked: __props.checked,
                        onChange: updateValue
                    }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_3),
                    (0, vue_2.createElementVNode)("div", _hoisted_4, [
                        (0, vue_2.createElementVNode)("span", {
                            class: (0, vue_2.normalizeClass)(__props.checked ? 'flex' : 'hidden')
                        }, "✔", 2 /* CLASS */)
                    ]),
                    (0, vue_2.createElementVNode)("span", _hoisted_5, (0, vue_2.toDisplayString)(__props.text), 1 /* TEXT */)
                ])
            ]));
        };
    }
});
