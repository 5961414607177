"use strict";
//import ShopResponseType from "./ShopResponseType";
Object.defineProperty(exports, "__esModule", { value: true });
const jsonMimeType = "application/json";
const jsonLdMimeType = "application/ld+json";
/**
 * Wrapper for fetch.
 *
 * Added Accept and Content-Type headers by default.
 *
 * @param {String|Request} request
 * @param {Object} options
 */
function default_1(request, 
// eslint-disable-next-line no-undef
options = {}) {
    var _a;
    if ("undefined" === typeof options.headers) {
        options.headers = new Headers();
    }
    else if (!(options.headers instanceof Headers) &&
        typeof options.headers === "object") {
        options.headers = new Headers(options.headers);
    }
    if (null === options.headers.get("Accept")) {
        options.headers.set("Accept", jsonLdMimeType);
    }
    if ("undefined" !== options.body &&
        !(options.body instanceof FormData) &&
        null === options.headers.get("Content-Type")) {
        options.headers.set("Content-Type", jsonMimeType);
    }
    if (!options.headers.has("Accept-Language")) {
        let lang = (_a = document.querySelector("html")) === null || _a === void 0 ? void 0 : _a.getAttribute("lang");
        if (lang) {
            options.headers.set("Accept-Language", `${lang},${navigator.languages.join(",")}`);
        }
    }
    // options.headers.set('Authorization', `Bearer ${token}`);
    options.credentials = "same-origin";
    options.mode = "same-origin";
    return fetch(request, options);
}
exports.default = default_1;
