"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
//import { PermissionInterface } from "../../models/Permission";
class UserGroupRequest {
    constructor(name = "", key = "", permissions = [] //IRIs
    ) {
        this.name = name;
        this.key = key;
        this.permissions = permissions;
    }
}
exports.default = UserGroupRequest;
