"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'DropdownItem',
    props: {
        // eslint-disable-next-line vue/require-default-prop
        to: {
            type: [String, Object],
        },
        pointer: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["selected"],
    setup(__props, { emit }) {
        const onSelect = () => {
            emit("selected");
        };
        return (_ctx, _cache) => {
            const _component_router_link = (0, vue_2.resolveComponent)("router-link");
            return (__props.to)
                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_router_link, {
                    key: 0,
                    to: __props.to,
                    class: (0, vue_2.normalizeClass)(["px-2 py-2 hover:bg-gray", { 'cursor-pointer': __props.pointer }]),
                    onClick: onSelect
                }, {
                    default: (0, vue_2.withCtx)(() => [
                        (0, vue_2.renderSlot)(_ctx.$slots, "default")
                    ]),
                    _: 3 /* FORWARDED */
                }, 8 /* PROPS */, ["to", "class"]))
                : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                    key: 1,
                    class: (0, vue_2.normalizeClass)(["px-2 py-2", { 'cursor-pointer': __props.pointer, 'hover:bg-gray': __props.pointer }]),
                    onClick: onSelect
                }, [
                    (0, vue_2.renderSlot)(_ctx.$slots, "default")
                ], 2 /* CLASS */));
        };
    }
});
