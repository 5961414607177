"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'FormNavigationButton',
    props: {
        text: {
            type: String,
            required: true,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["click"],
    setup(__props, { emit: onClick }) {
        function emitClick(event) {
            onClick("click", event);
        }
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("button", {
                class: (0, vue_2.normalizeClass)(["form-nav-button", { active: __props.isActive }]),
                onClick: emitClick
            }, (0, vue_2.toDisplayString)(__props.text), 3 /* TEXT, CLASS */));
        };
    }
});
