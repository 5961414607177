"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "banner-block" };
const _hoisted_2 = ["alt"];
const _hoisted_3 = { class: "banner-text" };
const _hoisted_4 = { class: "banner-bottom-text" };
const _hoisted_5 = { class: "font-micro" };
exports.default = (0, vue_1.defineComponent)({
    __name: 'Banner',
    props: {
        bannerText: {
            type: String,
            default: "",
        },
        bottomText: {
            type: String,
            default: "",
        },
        backgroundImage: {
            type: String,
            default: "",
        },
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, null, [
                (0, vue_2.createElementVNode)("div", _hoisted_1, [
                    (0, vue_2.createCommentVNode)(" :src=\"require(`@/assets/images/${backgroundImage}`)\" "),
                    (__props.backgroundImage !== '')
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("img", {
                            key: 0,
                            alt: __props.bannerText
                        }, null, 8 /* PROPS */, _hoisted_2))
                        : (0, vue_2.createCommentVNode)("v-if", true),
                    (0, vue_2.createElementVNode)("div", _hoisted_3, [
                        (__props.bannerText === '')
                            ? (0, vue_2.renderSlot)(_ctx.$slots, "bannerText", { key: 0 })
                            : (0, vue_2.createCommentVNode)("v-if", true)
                    ])
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_4, [
                    (0, vue_2.createElementVNode)("span", _hoisted_5, [
                        (__props.bottomText === '')
                            ? (0, vue_2.renderSlot)(_ctx.$slots, "bottomText", { key: 0 })
                            : (0, vue_2.createCommentVNode)("v-if", true)
                    ])
                ])
            ], 64 /* STABLE_FRAGMENT */));
        };
    }
});
