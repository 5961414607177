"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "h-full flex items-center justify-start cursor-pointer w-full" };
const _hoisted_2 = {
    key: 0,
    class: "flex grow flex-col items-start justify-center content-center overflow-hidden mr-4"
};
const _hoisted_3 = {
    key: 2,
    class: "marso-icon-chevron-down transform-gpu transition-transform text-xs",
    "aria-hidden": "true"
};
const vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'DropdownMenu',
    props: {
        buttonText: {
            type: String,
            default: "",
        },
        right: {
            type: Boolean,
            default: false,
        },
        hideCaret: {
            type: Boolean,
            default: false,
        },
        mobileFullWidth: {
            type: Boolean,
            default: false,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["selected"],
    setup(__props, { expose: __expose, emit }) {
        const props = __props;
        const activeMenu = (0, vue_3.ref)(false);
        //const dropdown: Ref<HTMLElement | null> = ref(null);
        const close = () => {
            activeMenu.value = false;
        };
        const itemSelected = () => {
            close();
            emit("selected");
        };
        const toggleMenu = () => {
            if (props.clickable) {
                activeMenu.value = !activeMenu.value;
            }
        };
        __expose({ close });
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                ref: "nav-dropdown",
                class: (0, vue_2.normalizeClass)(["relative nav-dropdown tab", {
                        active: activeMenu.value,
                        'mobile-full-width': __props.mobileFullWidth,
                    }]),
                onClick: _cache[0] || (_cache[0] = ($event) => (toggleMenu()))
            }, [
                (0, vue_2.createElementVNode)("span", _hoisted_1, [
                    (__props.buttonText.length)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_2, [
                            (0, vue_2.createElementVNode)("div", null, (0, vue_2.toDisplayString)(__props.buttonText), 1 /* TEXT */)
                        ]))
                        : (0, vue_2.renderSlot)(_ctx.$slots, "buttonText", { key: 1 }),
                    (!__props.hideCaret)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("i", _hoisted_3))
                        : (0, vue_2.createCommentVNode)("v-if", true)
                ]),
                (0, vue_2.createElementVNode)("div", {
                    class: (0, vue_2.normalizeClass)(["dropdown-menu rounded-b-md", {
                            '-ml-2': !__props.right,
                            'right-0': __props.right,
                        }])
                }, [
                    (0, vue_2.renderSlot)(_ctx.$slots, "default", { itemSelected: itemSelected })
                ], 2 /* CLASS */)
            ], 2 /* CLASS */));
        };
    }
});
