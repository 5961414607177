"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "flex flex-col" };
const _hoisted_2 = { class: "label-mb-bottom" };
const _hoisted_3 = { class: "relative" };
const _hoisted_4 = ["disabled", "name"];
const _hoisted_5 = { class: "select-icon" };
exports.default = (0, vue_1.defineComponent)({
    __name: 'CustomSelect',
    props: {
        label: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "text",
        },
        name: {
            type: String,
            default: "",
        },
        active: {
            type: Boolean,
            default: true,
        },
    },
    emits: ["update:modelValue"],
    setup(__props) {
        return (_ctx, _cache) => {
            const _component_font_awesome_icon = (0, vue_2.resolveComponent)("font-awesome-icon");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("label", _hoisted_2, (0, vue_2.toDisplayString)(__props.label), 1 /* TEXT */),
                (0, vue_2.createElementVNode)("span", _hoisted_3, [
                    (0, vue_2.createElementVNode)("select", {
                        disabled: __props.active === false,
                        name: __props.name,
                        class: "select",
                        onChange: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('update:modelValue', $event.target.value)))
                    }, [
                        (0, vue_2.renderSlot)(_ctx.$slots, "default")
                    ], 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_4),
                    (0, vue_2.createElementVNode)("span", _hoisted_5, [
                        (0, vue_2.createVNode)(_component_font_awesome_icon, { icon: ['fa', 'caret-down'] })
                    ])
                ])
            ]));
        };
    }
});
