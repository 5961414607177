"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopConfig = void 0;
class ShopConfig {
    static getAppEnv() {
        return this.config.env.APP_ENV;
    }
    static hasAppEnv() {
        return (this.config.hasOwnProperty("env") &&
            this.config["env"].hasOwnProperty("APP_ENV"));
    }
    static getLocale() {
        return this.config.env.LOCALE;
    }
    static hasLocale() {
        return (this.config.hasOwnProperty("env") &&
            this.config["env"].hasOwnProperty("LOCALE"));
    }
    static getShopLocation() {
        return this.config.env.SHOP_LOCATION;
    }
    static hasShopLocation() {
        return (this.config.hasOwnProperty("env") &&
            this.config["env"].hasOwnProperty("SHOP_LOCATION"));
    }
    static getTranslations() {
        return this.config.translations;
    }
    static hasTranslations() {
        return this.config.hasOwnProperty("translations");
    }
}
/* @ts-ignore */
ShopConfig.config = window.shopConfig;
exports.ShopConfig = ShopConfig;
