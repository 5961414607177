"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "item" };
const _hoisted_2 = { class: "datas" };
const _hoisted_3 = { class: "top" };
const _hoisted_4 = { class: "bot" };
const _hoisted_5 = { class: "buttons" };
const _hoisted_6 = { class: "approve" };
const _hoisted_7 = { class: "decline" };
const date_fns_1 = require("date-fns");
exports.default = (0, vue_1.defineComponent)({
    __name: 'AppointmentItem',
    props: {
        arrivedDate: {
            type: Date,
            required: true,
        },
        email: {
            type: String,
            required: true,
        },
        serviceBay: {
            type: String,
            required: true,
        },
        bookedDate: {
            type: Date,
            required: true,
        },
        bookedTime: {
            type: Date,
            required: true,
        },
        licensePlate: {
            type: String,
            required: true,
        },
        carBrand: {
            type: String,
            required: true,
        },
        carModel: {
            type: String,
            required: true,
        },
    },
    emits: ["accept", "decline"],
    setup(__props, { emit }) {
        const accept = () => {
            emit("accept");
        };
        const decline = () => {
            emit("decline");
        };
        return (_ctx, _cache) => {
            const _component_font_awesome_icon = (0, vue_2.resolveComponent)("font-awesome-icon");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createElementVNode)("div", _hoisted_3, [
                        (0, vue_2.createElementVNode)("span", null, (0, vue_2.toDisplayString)(_ctx.$trans("recieved", "Recieved", "home")) + ": " + (0, vue_2.toDisplayString)((0, vue_2.unref)(date_fns_1.format)(__props.arrivedDate, "yyyy-MM-dd HH:mm")), 1 /* TEXT */),
                        (0, vue_2.createTextVNode)(" | "),
                        (0, vue_2.createElementVNode)("span", null, (0, vue_2.toDisplayString)(__props.email), 1 /* TEXT */)
                    ]),
                    (0, vue_2.createElementVNode)("div", _hoisted_4, [
                        (0, vue_2.createElementVNode)("div", null, (0, vue_2.toDisplayString)(__props.serviceBay) + " | " + (0, vue_2.toDisplayString)((0, vue_2.unref)(date_fns_1.format)(__props.bookedDate, "yyyy-MM-dd")) + " " + (0, vue_2.toDisplayString)((0, vue_2.unref)(date_fns_1.format)(__props.bookedTime, "HH:mm")), 1 /* TEXT */),
                        (0, vue_2.createElementVNode)("div", null, (0, vue_2.toDisplayString)(__props.licensePlate) + " - " + (0, vue_2.toDisplayString)(__props.carBrand) + " " + (0, vue_2.toDisplayString)(__props.carModel), 1 /* TEXT */)
                    ])
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_5, [
                    (0, vue_2.createElementVNode)("button", _hoisted_6, [
                        (0, vue_2.createVNode)(_component_font_awesome_icon, {
                            class: "text-bhp text-6xl",
                            icon: ['far', 'square-check'],
                            onClick: accept
                        })
                    ]),
                    (0, vue_2.createElementVNode)("button", _hoisted_7, [
                        (0, vue_2.createVNode)(_component_font_awesome_icon, {
                            class: "text-marso text-6xl",
                            icon: ['far', 'rectangle-xmark'],
                            onClick: decline
                        })
                    ])
                ])
            ]));
        };
    }
});
