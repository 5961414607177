"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "form-input" };
const _hoisted_2 = ["name", "checked", "value"];
const _hoisted_3 = { class: "img-container" };
const _hoisted_4 = ["src"];
const _hoisted_5 = { class: "radio-text" };
exports.default = (0, vue_1.defineComponent)({
    __name: 'CustomRadioWithImage',
    props: {
        name: {
            type: String,
            required: true,
        },
        imgSrc: {
            type: String,
            default: "",
        },
        text: {
            type: String,
            default: "",
        },
        value: {
            type: String,
            default: "",
        },
        modelValue: {
            type: String,
            default: "",
        },
        checked: {
            type: Boolean,
            default: false,
        },
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("label", {
                    class: (0, vue_2.normalizeClass)(["radio-label", { checked: __props.checked }])
                }, [
                    (0, vue_2.createElementVNode)("input", {
                        type: "radio",
                        name: __props.name,
                        required: "",
                        checked: __props.checked,
                        value: __props.value
                    }, null, 8 /* PROPS */, _hoisted_2),
                    (0, vue_2.createElementVNode)("div", _hoisted_3, [
                        (0, vue_2.createElementVNode)("img", { src: __props.imgSrc }, null, 8 /* PROPS */, _hoisted_4)
                    ]),
                    (0, vue_2.createElementVNode)("div", _hoisted_5, (0, vue_2.toDisplayString)(__props.text), 1 /* TEXT */)
                ], 2 /* CLASS */)
            ]));
        };
    }
});
