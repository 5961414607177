"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "title" };
exports.default = (0, vue_1.defineComponent)({
    __name: 'CardTitle',
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, (0, vue_2.toDisplayString)(__props.title), 1 /* TEXT */));
        };
    }
});
