"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "form-input" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = ["rows", "placeholder", "name", "value", "maxlength"];
exports.default = (0, vue_1.defineComponent)({
    __name: 'CustomTextArea',
    props: {
        placeholder: {
            type: String,
            default: "",
        },
        customClass: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        rows: {
            type: Number,
            default: 1,
        },
        value: {
            type: String,
            default: "",
        },
        // eslint-disable-next-line vue/require-default-prop
        maxLength: {
            type: Number,
            required: false,
        },
    },
    emits: ["update:value"],
    setup(__props, { emit }) {
        //import { defineProps, defineEmits } from "vue";
        const onInput = (event) => {
            const target = event.target;
            emit("update:value", target.value);
        };
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (__props.label)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("label", _hoisted_2, (0, vue_2.toDisplayString)(__props.label), 1 /* TEXT */))
                    : (0, vue_2.createCommentVNode)("v-if", true),
                (0, vue_2.createElementVNode)("textarea", {
                    rows: `${__props.rows}`,
                    placeholder: `${__props.placeholder}`,
                    class: (0, vue_2.normalizeClass)(`${__props.customClass}`),
                    name: `${__props.name}`,
                    value: __props.value,
                    maxlength: __props.maxLength,
                    onInput: onInput
                }, "\n    ", 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_3)
            ]));
        };
    }
});
