"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = {
    key: 0,
    class: "card-label"
};
const _hoisted_2 = {
    key: 0,
    class: "card-title"
};
exports.default = (0, vue_1.defineComponent)({
    __name: 'Card',
    props: {
        title: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        customClass: {
            type: String,
            default: "",
        },
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, null, [
                (__props.label)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, (0, vue_2.toDisplayString)(__props.label), 1 /* TEXT */))
                    : (0, vue_2.createCommentVNode)("v-if", true),
                (0, vue_2.createElementVNode)("div", {
                    class: (0, vue_2.normalizeClass)(["card", `${__props.customClass}`])
                }, [
                    (__props.title)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_2, (0, vue_2.toDisplayString)(__props.title), 1 /* TEXT */))
                        : (0, vue_2.createCommentVNode)("v-if", true),
                    (0, vue_2.renderSlot)(_ctx.$slots, "default")
                ], 2 /* CLASS */)
            ], 64 /* STABLE_FRAGMENT */));
        };
    }
});
