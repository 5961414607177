"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "mb-2" };
const _hoisted_2 = {
    key: 0,
    class: "block pb-2 font-bold uppercase tracking-wide text-sm"
};
const _hoisted_3 = { class: "flex items-center" };
const _hoisted_4 = ["accept", "placeholder"];
exports.default = (0, vue_1.defineComponent)({
    __name: 'FileInput',
    props: {
        label: {
            type: String,
            required: false,
            default: "",
        },
        modelValue: {
            type: [File, () => null],
            default: null,
        },
        // eslint-disable-next-line vue/require-default-prop
        placeholder: {
            type: String,
            required: false,
        },
        // eslint-disable-next-line vue/require-default-prop
        accept: {
            type: String,
            required: false,
        },
    },
    emits: ["update:modelValue"],
    setup(__props, { emit }) {
        const updateModel = (event) => {
            emit("update:modelValue", event.target.files[0]);
        };
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (__props.label)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_2, (0, vue_2.toDisplayString)(__props.label), 1 /* TEXT */))
                    : (0, vue_2.createCommentVNode)("v-if", true),
                (0, vue_2.createElementVNode)("div", _hoisted_3, [
                    (0, vue_2.createElementVNode)("input", {
                        accept: __props.accept,
                        type: "file",
                        class: "block grow",
                        placeholder: __props.placeholder,
                        onInput: updateModel
                    }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_4),
                    (0, vue_2.createElementVNode)("div", null, [
                        (0, vue_2.renderSlot)(_ctx.$slots, "button")
                    ])
                ])
            ]));
        };
    }
});
