"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "mb-2" };
const _hoisted_2 = {
    key: 0,
    class: "block pb-2 text-sm font-bold uppercase tracking-wide"
};
const _hoisted_3 = ["accept", "placeholder"];
const _hoisted_4 = {
    key: 1,
    class: "text-red mt-2 text-sm"
};
exports.default = (0, vue_1.defineComponent)({
    __name: 'FileInputBase64',
    props: {
        base64EncodeFile: {
            type: [String, () => null],
            default: null,
        },
        // eslint-disable-next-line vue/require-default-prop
        originalFilename: String,
        // eslint-disable-next-line vue/require-default-prop
        label: {
            type: String,
            required: false,
        },
        // eslint-disable-next-line vue/require-default-prop
        placeholder: String,
        // eslint-disable-next-line vue/require-default-prop
        accept: {
            type: String,
            required: false,
        },
        showValidity: {
            type: Boolean,
            default: false,
        },
        valid: {
            type: Boolean,
            default: true,
            required: false,
        },
        invalidMessage: {
            type: String,
            required: false,
            default: "",
        },
    },
    emits: [
        "update:base64EncodeFile",
        "update:originalFilename",
    ],
    setup(__props, { emit }) {
        const updateModel = (event) => {
            const file = event.target.files[0];
            if (file === undefined) {
                emit("update:base64EncodeFile", null);
                return;
            }
            emit("update:originalFilename", file.name);
            const reader = new FileReader();
            reader.onloadend = () => {
                emit("update:base64EncodeFile", reader.result);
            };
            reader.readAsDataURL(file);
        };
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (__props.label)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_2, (0, vue_2.toDisplayString)(__props.label), 1 /* TEXT */))
                    : (0, vue_2.createCommentVNode)("v-if", true),
                (0, vue_2.createElementVNode)("input", {
                    accept: __props.accept,
                    class: (0, vue_2.normalizeClass)(["block w-full text-sm text-slate-500 file:mr-4 file:rounded-full file:border-0 file:px-4 file:py-2 file:text-sm file:font-semibold", {
                            'file:bg-green': __props.valid && __props.showValidity,
                            'file:bg-red file:text-white': !__props.valid && __props.showValidity,
                        }]),
                    type: "file",
                    placeholder: __props.placeholder,
                    onInput: updateModel
                }, null, 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_3),
                (__props.showValidity && __props.invalidMessage !== '' && !__props.valid)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_4, (0, vue_2.toDisplayString)(__props.invalidMessage), 1 /* TEXT */))
                    : (0, vue_2.createCommentVNode)("v-if", true)
            ]));
        };
    }
});
