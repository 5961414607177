"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'CustomButton',
    props: {
        label: {
            type: String,
            default: "",
        },
        image: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "red",
        },
    },
    emits: ["pressed"],
    setup(__props, { emit: emits }) {
        const press = () => {
            emits("pressed");
        };
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("button", {
                class: (0, vue_2.normalizeClass)(["button", {
                        'text-white bg-red': __props.type === 'red',
                        'button-search': __props.type === 'search',
                    }]),
                onClick: press
            }, [
                (__props.image === '')
                    ? (0, vue_2.renderSlot)(_ctx.$slots, "image", { key: 0 }, () => [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(__props.image), 1 /* TEXT */)
                    ])
                    : (0, vue_2.createCommentVNode)("v-if", true),
                (__props.label === '')
                    ? (0, vue_2.renderSlot)(_ctx.$slots, "default", { key: 1 }, () => [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(__props.label), 1 /* TEXT */)
                    ])
                    : (0, vue_2.createCommentVNode)("v-if", true)
            ], 2 /* CLASS */));
        };
    }
});
