"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "form-title" };
const _hoisted_2 = { class: "img" };
const _hoisted_3 = ["src", "alt"];
exports.default = (0, vue_1.defineComponent)({
    __name: 'CustomTitle',
    props: {
        icon: {
            type: String,
            default: "",
        },
        text: {
            type: String,
            default: "",
        },
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (__props.icon)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("img", {
                            key: 0,
                            class: "w-full h-auto",
                            src: require(`../../images/frontend/${__props.icon}`),
                            alt: __props.text
                        }, null, 8 /* PROPS */, _hoisted_3))
                        : (0, vue_2.createCommentVNode)("v-if", true),
                    (0, vue_2.createCommentVNode)("  :src=\"require(`@/assets/images/${icon}`)\"  ")
                ]),
                (0, vue_2.createElementVNode)("h2", null, (0, vue_2.toDisplayString)(__props.text), 1 /* TEXT */)
            ]));
        };
    }
});
