"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "footer-item" };
const _hoisted_2 = { class: "icon" };
const _hoisted_3 = { class: "text" };
exports.default = (0, vue_1.defineComponent)({
    __name: 'CalendarFooterItems',
    props: {
        text: {
            type: String,
            default: "",
        },
        icon: {
            type: Boolean,
            default: false,
        },
        circleClass: {
            type: String,
            default: "",
        },
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", {
                    class: (0, vue_2.normalizeClass)(["circle", `${__props.circleClass}`])
                }, [
                    (0, vue_2.createElementVNode)("div", _hoisted_2, [
                        (0, vue_2.renderSlot)(_ctx.$slots, "default", { class: "icon" })
                    ])
                ], 2 /* CLASS */),
                (0, vue_2.createElementVNode)("div", _hoisted_3, (0, vue_2.toDisplayString)(__props.text), 1 /* TEXT */)
            ]));
        };
    }
});
