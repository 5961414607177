"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "form-input" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = ["disabled", "name"];
exports.default = (0, vue_1.defineComponent)({
    __name: 'CustomSelect',
    props: {
        placeholder: {
            type: String,
            default: "",
        },
        active: {
            type: Boolean,
            default: true,
        },
        customClass: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
    },
    emits: ["update:modelValue"],
    setup(__props) {
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (__props.label)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("label", _hoisted_2, (0, vue_2.toDisplayString)(__props.label), 1 /* TEXT */))
                    : (0, vue_2.createCommentVNode)("v-if", true),
                (0, vue_2.createElementVNode)("select", {
                    disabled: !__props.active,
                    name: `${__props.name}`,
                    class: (0, vue_2.normalizeClass)(`${__props.customClass}`),
                    onChange: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('update:modelValue', $event.target.value)))
                }, [
                    (0, vue_2.renderSlot)(_ctx.$slots, "default")
                ], 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_3)
            ]));
        };
    }
});
